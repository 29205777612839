html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 62.5%;
}

body {
  background: #e7e7e7;
  margin: 0;
  padding: 0;
	-webkit-text-size-adjust: 100%;
	font-size: 1.8rem;
  line-height: 1.4;
}

@media (max-width: 4.8rem) {
  body {
    margin: 0;
    padding: 1.6rem;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
}

h2 {
  font-size: 1.15em;
  font-weight: 500;
  letter-spacing: -0.1px;

  @media (min-width: 22em) {
    font-size: 1.35em;
  }
}

h3 {
  font-size: 1.15em;
}

a, a:link, a:visited, a:active, a:hover {
  font-weight: normal;
  color: #000;
  text-decoration: underline;
}


body.js-lightbox-open {
  overflow: hidden;
}
body.js-lightbox-open .l-main {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  filter: blur(2px);
}

body.js-lightbox-open shady-boxes {
  display: block;
}

@media (max-width: 500px) {
  shady-box figcaption {
    padding: 1.2rem;
  }
}

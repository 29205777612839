@import "../../node_modules/normalize.css/normalize.css";
@import "styles/mixins";
@import "styles/base";
@import "styles/links";
@import "styles/components/project";
@import "styles/header";

b, strong {
  font-weight: 600;
}


.s-body {
  @include well-padding;

  display: grid;
  font-size: 1.6rem;
  grid-template-columns: 35vw 2.4rem auto;
  grid-template-rows: auto auto auto auto;

  @media (min-width: 22em) {
    grid-template-columns: 38vw 2.4rem auto;
    grid-template-rows: auto auto auto auto;
  }

  @media (min-width: 32em) {
    grid-template-columns: 45vw 3.2rem auto;
    grid-template-rows: auto auto auto;
  }

  @media (min-width: 48em) {
    grid-template-columns: auto 4.8rem 20rem;
    grid-template-rows: auto auto auto auto;
    -ms-grid-columns: calc(100vw - 34.4rem) 4.8rem 20rem;
    -ms-grid-rows: 32.5rem 11.4rem 19rem;
  }

  @media (min-width: 67em) {
    -ms-grid-columns: 66.6rem 9.4rem 20rem;    
  }
}

.s-content {
  grid-column: 1 / span 3;
  grid-row: 3;

  @media (min-width: 48em) {
    grid-column: 1;
    grid-row: 1 / span 4;
    // IE 11 hack
    max-width: calc(100vw - 34.4rem);
  }
  @media (min-width: 67em) {
    max-width: 66.6rem;
  }
}

.s-sidebar {
  box-sizing: border-box;
  grid-column: 3;
  font-size: 1.6rem;
  margin-bottom: 1em;

  ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  h2 {
    margin-top: 0;

    @media (min-width: 48em) {
      margin-top: 0.83em;
    }
  }

  p, ul {
    line-height: 1.5;
    font-size: 1.4rem;

    @media (min-width: 36em) {
      font-size: 1.6rem;
    }
  }
}

.c-resume__link {
  display: block;
  max-width: 20rem;
}

.c-resume {
  grid-column: 1;
  grid-row: 1 / span 2;
}
.c-contact {
  grid-row: 1;
}
.c-projects {
  grid-row: 2;

  h2 {
    margin-top: 0.83em;
  }
}

@media (min-width: 48em) {
  .c-resume {
    grid-column: 3;
    grid-row: 1;
  }
  .c-contact {
    grid-column: 3;
    grid-row: 2;
  }
  .c-projects {
    grid-column: 3;
    grid-row: 3;
  }
}


shady-box {
  display: none;
}

.links {
  line-height: 1.5;

  @media (max-width: 32em) {
    padding: 0 0 0 1.1em;
  }

  @media (max-width: 22em) {
    font-size: 0.9em;
  }

  a {
    font-weight: 500;
    letter-spacing: -0.1px;
  }

  a[href^="http"]::after {
    content: '\00a0\00a0\00a0\00a0';
    display: inline-block;
    height: 1.8rem;
    width: 1.9rem;
    background: url('/static/images/icons/external-link.svg') no-repeat bottom right;
    background-size: 1.4rem 1.4rem;
    box-sizing: border-box;
    vertical-align: baseline;
    text-decoration: underline;
  }
}

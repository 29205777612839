@mixin well-padding {
  box-sizing: content-box;
  max-width: 96rem;
  padding: 0 1.8rem;
  margin: 0 auto;

  @media (min-width: 23.5em) { padding: 0 2.4rem; }
  @media (min-width: 32em) { padding: 0 3.2rem; }
  @media (min-width: 48em) { padding: 0 4.8rem; }
}

@import "styles/mixins";

.c-banner {
  overflow: hidden;
  height: 5.5rem;
  margin-bottom: 2rem;
  background: #222;
}

.c-banner__hed {
  @include well-padding;

  text-align: left;
  z-index: 2;
  line-height: 2;
  font-family: "Univers", "Helvetica Neue", arial, helvetica, sans-serif;
  font-size: 2.7rem;
}

a.c-banner__link {
  letter-spacing: 0.1rem;
  color: #fff;
  font-weight: normal;
  -webkit-text-shadow: rgba(0, 0, 0, 0.3) 0 0.1rem 0.1rem;
     -moz-text-shadow: rgba(0, 0, 0, 0.3) 0 0.1rem 0.1rem;
          text-shadow: rgba(0, 0, 0, 0.3) 0 0.1rem 0.1rem;
  display: block;
  text-decoration: none;
}

a.c-banner__link:hover {
    text-decoration: none;
}

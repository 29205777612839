.project {
  padding: 1.8rem;
  background: #fff;
  border: 0.1rem solid #ddd;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 3.2rem;

  @media (min-width: 23.5em) { padding: 2.4rem; }
  @media (min-width: 32em) { padding: 3.2rem; }

  @media (min-width: 48em) {
    padding: 3.2rem;
    max-width: 66.6rem;
  }
}

.project > *:last-child {
  margin-bottom: 0;
}

.project__header {
  margin-bottom: 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  .project--juvenile-justice & {
    display: block;

    @media (min-width: 48em) {
      display: flex;
    }
  }
}

.project__subtitle {
  margin-top: 0.4em;
  margin-bottom: 0;
  font-weight: 500;
  letter-spacing: -0.5px;
  flex-basis: 100%;
}

.project__title {
  flex-basis: calc(100% - 8rem);
  margin: 0 0 0.25em 0;

  @media (min-width: 36em) {
    margin: 0;
  }
}

.project__date {
  color: #666;
  font-size: 1.5rem;
  white-space: nowrap;
}

.project__header__top {
  flex-direction: column;

  @media (min-width: 36em) {
    flex-direction: row;
  }
}

.project__dl {
  display: none;
  align-items: baseline;
  justify-content: flex-start;
}

.project__dl__item--dt {
  white-space: nowrap;
}

.project__screenshot, .project__video {
  margin-bottom: 2.4rem;
  @media (min-width: 32em) { margin-bottom: 3.2rem; }
  @media (min-width: 48em) { margin-bottom: 4.8rem; }
}

//@media (max-width: 48rem) {
//  .project {
//    max-width: 100%;
//  }
//  .project--juvenile-justice .project__header {
//    display: block;
//  }
//}
